<template>
  <div>
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="6" v-access data-ctrl="dashBoard.user"
        ><div class="grid-content bg-purple">
          <div class="dashboard-header">
            <i class="iconfont iconCustomermanagement-fill"></i>
            用户
          </div>
          <div class="dashboard-content">
            <div class="dashboard-total">统计</div>
            <div class="dashboard-content-item">
              <div>总数</div>
              <div class="number" v-prev path="/system/user/list">
                {{ userAnalyse.total }}
              </div>
            </div>
            <div class="dashboard-content-item">
              <div>已认证</div>
              <router-link
                :to="{ path: '/system/user/list', query: { isAuth: '1' } }"
                >{{ userAnalyse.certified }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>本日新增</div>
              <router-link
                :to="{ path: '/system/user/list', query: { time: '1' } }"
                >{{ userAnalyse.dayAdd }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>本月新增</div>
              <router-link
                :to="{ path: '/system/user/list', query: { time: '2' } }"
                >{{ userAnalyse.monthAdd }}</router-link
              >
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" v-access data-ctrl="dashBoard.enterprise"
        ><div class="grid-content bg-purple">
          <div class="dashboard-header">
            <i class="iconfont iconcompany-fill"></i> 企业
          </div>
          <div class="dashboard-content">
            <div class="dashboard-total">统计</div>
            <div class="dashboard-content-item">
              <div>总数</div>
              <div class="number" v-prev path="/system/enterprise/list">
                100
              </div>
            </div>
            <div class="dashboard-content-item">
              <div>已认证</div>
              <div class="number" v-prev path="/system/enterprise/list">
                100
              </div>
            </div>
            <div class="dashboard-content-item">
              <div>本日新增</div>
              <div class="number" v-prev path="/system/enterprise/list">
                100
              </div>
            </div>
            <div class="dashboard-content-item">
              <div>本月新增</div>
              <div class="number" v-prev path="/system/enterprise/list">
                100
              </div>
            </div>
          </div>
        </div></el-col
      >
      <el-col :span="6" v-access data-ctrl="dashBoard.task"
        ><div class="grid-content bg-purple">
          <div class="dashboard-header">
            <i class="iconfont icontask-management-fill"></i>任务
          </div>
          <div class="dashboard-content">
            <div class="dashboard-total">统计</div>
            <div class="dashboard-content-item">
              <div>总数</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>未开始</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>待指派</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>进行中</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>已完成</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>异常/超时</div>
              <div class="number">100</div>
            </div>
          </div>
        </div></el-col
      >
      <el-col :span="6" v-access data-ctrl="dashBoard.contract"
        ><div class="grid-content bg-purple">
          <div class="dashboard-header">
            <i class="iconfont icondefault-template-fill"></i>合同
          </div>
          <div class="dashboard-content">
            <div class="dashboard-total">统计</div>
            <div class="dashboard-content-item">
              <div>总数</div>
              <div class="number" v-prev path="/system/contract/list">
                {{ contractAnalyse.total }}
              </div>
            </div>
            <div class="dashboard-content-item">
              <div>已签署</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { state: 3 }
                }"
                >{{ contractAnalyse.signed }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>待签署</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { state: 2 }
                }"
                >{{ contractAnalyse.wait }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>签署失败</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { state: 4 }
                }"
                >{{ contractAnalyse.fail }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>今日到期</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { deadTime: 1 }
                }"
                >{{ contractAnalyse.todayOver }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>近一周到期</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { deadTime: 2 }
                }"
                >{{ contractAnalyse.thisWeekOver }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>近一月到期</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { deadTime: 3 }
                }"
                >{{ contractAnalyse.thisMonthOver }}</router-link
              >
            </div>
            <div class="dashboard-content-item">
              <div>已过期</div>
              <router-link
                :to="{
                  path: '/system/contract/list',
                  query: { deadTime: 4 }
                }"
                >{{ contractAnalyse.over }}</router-link
              >
              <!-- <div class="number" @click="goContract()">100</div> -->
            </div>
          </div>
        </div></el-col
      >
      <el-col :span="6" v-access data-ctrl="dashBoard.pay"
        ><div class="grid-content bg-purple">
          <div class="dashboard-header">
            <i class="iconfont icondefault-template-fill"></i>支付
          </div>
          <div class="dashboard-content">
            <div class="dashboard-total">统计</div>
            <div class="dashboard-content-item">
              <div>总笔数</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>今日交易额</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>本周交易额</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>本月交易额</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>本年交易额</div>
              <div class="number">100</div>
            </div>
            <div class="dashboard-content-item">
              <div>总金额</div>
              <div class="number">100</div>
            </div>
          </div>
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
//contract.analyse.json
import { api } from '/src/api/base';
export const ContractAnalyse = api('/contract')('contract.analyse.json');
export const UserAnalyse = api('/user')('user.analyse.json');

export default {
  data() {
    return {
      contractAnalyse: {},
      userAnalyse: {}
    };
  },
  async mounted() {
    this.contractAnalyse = await ContractAnalyse();
    this.userAnalyse = await UserAnalyse();
  },
  methods: {
    goContract() {}
  }
};
</script>

<style>
.grid-content {
  /* background: #f7f7f7; */
}
.dashboard-header {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #50bfff;
  background-color: #ecf8ff;
  cursor: pointer;
  margin-bottom: 5px;
  color: #666;
  border-start-start-radius: 6px;
  border-start-end-radius: 6px;
}
.dashboard-header:hover {
  color: #1989fa;
}
.dashboard-header .iconfont {
  font-size: 22px;
}
.dashboard-content {
  padding: 10px;
  border: 1px solid #50bfff;
  min-height: 310px;
  background-color: #ecf8ff;
  color: #666;
}
.dashboard-content .number:hover {
  color: #1989fa;
  text-decoration: underline;
  cursor: pointer;
}
.dashboard-total {
  font-size: 18px;
  font-weight: 500;
}
.dashboard-content-item {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.block-title {
  font-weight: 400;
  color: #1f2f3d;

  margin: 25px 0 20px;
  font-size: 22px;
}
</style>
